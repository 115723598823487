// extracted by mini-css-extract-plugin
export var EditIconProfile = "profileDetails-module--EditIconProfile--4f6ea";
export var Iconmail = "profileDetails-module--Iconmail--445b1";
export var KimberImage = "profileDetails-module--KimberImage--91904";
export var ProfileRowDiv = "profileDetails-module--ProfileRowDiv--bf6c1";
export var ProfilecardDiv = "profileDetails-module--ProfilecardDiv--1fb44";
export var agentImage = "profileDetails-module--agentImage--58135";
export var agentbut = "profileDetails-module--agentbut--def3d";
export var cameraImage = "profileDetails-module--cameraImage--3e2ab";
export var cardnumber = "profileDetails-module--cardnumber--cfc95";
export var claimbut = "profileDetails-module--claimbut--d6c7f";
export var dashboardActive = "profileDetails-module--dashboardActive--f108c";
export var deleteAccountbut = "profileDetails-module--deleteAccountbut--132ac";
export var deletedoc = "profileDetails-module--deletedoc--77dd2";
export var deletedoc1 = "profileDetails-module--deletedoc1--3427c";
export var documentname = "profileDetails-module--documentname--304a4";
export var edit = "profileDetails-module--edit--67217";
export var editdetails = "profileDetails-module--editdetails--86337";
export var editname = "profileDetails-module--editname--52652";
export var horizontalline = "profileDetails-module--horizontalline--05a6c";
export var inputdoc = "profileDetails-module--inputdoc--c54b2";
export var introLetter = "profileDetails-module--introLetter--6b378";
export var kimberimage = "profileDetails-module--kimberimage--21acf";
export var listedhead = "profileDetails-module--listedhead--d8afb";
export var phone = "profileDetails-module--phone--c23cf";
export var phonenum = "profileDetails-module--phonenum--a9cfc";
export var phonenum1 = "profileDetails-module--phonenum1--5ea09";
export var phonenum2 = "profileDetails-module--phonenum2--5136d";
export var phonenum3 = "profileDetails-module--phonenum3--a9486";
export var preAprroval = "profileDetails-module--preAprroval--97031";
export var profilebar = "profileDetails-module--profilebar--19671";
export var profiledetail = "profileDetails-module--profiledetail--53688";
export var profilehead = "profileDetails-module--profilehead--93b56";
export var profilehead1 = "profileDetails-module--profilehead1--dc0bb";
export var profiles = "profileDetails-module--profiles--a77d0";
export var profilestatus = "profileDetails-module--profilestatus--61a37";
export var progressBar = "profileDetails-module--progressBar--2fb8c";
export var proofletter = "profileDetails-module--proofletter--8adef";
export var saveicon = "profileDetails-module--saveicon--9b99e";
export var statusupdate = "profileDetails-module--statusupdate--6c14e";
export var view = "profileDetails-module--view--7db2a";